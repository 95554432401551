.no-underline {
  text-decoration: none;
}

a {
  color: $white;
}

.project-link {
  color: $brand !important;
}

a:hover {
  color: $primary;
}

.not-current-link {
  color: rgba($gray-400, 0.7);
}

.side-nav-item:hover {
  color: $brand;
}

.project-content-box a {
  color: $primary;
}

.project-content-box a:hover {
  color: $violet-dark;
}
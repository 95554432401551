.modal {
  display: none; 
  position: fixed; 
  z-index: 50; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  padding-top: 0; 
  background-color:$grey-light;
  --bs-modal-border-radius: 0rem;

  @media (min-width: $bp-desktop) {
    background-color: rgba(0,0,0,0.7); 
    padding-top: 100px; 
    border: none;
  }
}

.modal-content {
  background-color: $grey-light;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;

  @media (min-width: $bp-desktop) {
    max-width: 50%;
  }
}

.closeModal {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal h3, .modal p, .modal label {
  color: $brand;
  font-weight: 600;
}

.modal input {
  color: $brand;
  font-weight: 600;

}


.btn {
  border-radius: 0;
}

.btn-primary { 
  color: $grey-light;
}

.socials {
  display: block;
  height: 4rem;
  @media (min-width: $bp-desktop) {
    height: 3.5rem;
  } 
}
  .btn-circle {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    border-radius: 50%;
    border: 1px solid #F3F3EF;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.42857;
    @media (min-width: $bp-desktop) {
      width: 3.5rem;
      height: 3.5rem;
      padding: 0.8rem;
      font-size: 1.5rem;
    } 
  }

.btn-circle-white {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid $brand;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.42857;
  @media (min-width: $bp-desktop) {
    width: 3rem;
    height: 3rem;
    padding: 0.8rem;
    font-size: 1.5rem;
  } 
}

.btn-circle:hover, .btn-circle-white:hover {
  border: 1px solid $button-cta-background-color;
  background-color: $button-cta-background-color;
}

.btn-menu:hover{
  border-radius: 25px;
}

.btn-close{
  margin-left: auto;
}

.dark-btn:hover{
  background-color: $brand;
  border: 1px solid $brand;
}

/* #TODO arrow-btn */
.offer-content{
  width: 100%;

  @media (min-width: $bp-desktop) {
      width: 60%;
  }
} 

#offer_content {
  padding-top: 5%;
}
.info__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  flex-wrap: wrap;

  .info__text {
    color: $grey-light;
  }

  .info__btn {
    background-color: $grey-light;
    color: $primary;
    font-weight: bold;
  }
}

#sidebar {
  margin-top: 25%;
}

.service-content-box {

  a {
    color: $primary;

    &:hover {
      font-weight: bolder;
    }
  }

  p {
    text-wrap: balance;

    img {
      display: block;
      margin: auto;
      width: 100%;

      @media (min-width: $bp-desktop) {
        width: 60%;
      }
    }
  }
}

#service-content {
  z-index: 999;
}

.video__play {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    @media (min-width: $bp-desktop) {
        margin-bottom: -20%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media (min-width: $bp-desktop) {
            width: 60%;
            height: 60%;
            top: auto;
            left: auto;
            display: flex;
            justify-content: center;
        }
    }
}
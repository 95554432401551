html {
  background-color: $brand;
  font-size: 1rem;
}

body {
  background-color: $brand;
}


ul {
  list-style: none;
}

ul li:before {
  content: "■";
  color: #e94668;
  padding-right: 1em;
}

.navbar__list li:before {
  content: "";
  padding-right: 0em;
}

.w-60 {
  width: 60%;
}
.w-lg-60 {
  @media (min-width: $bp-desktop) {
    width: 60%;
  } 
}

.w-40 {
  width: 40%;
}

.fit-content {
  width: fit-content;
}

.side-menu{
    background-color: $primary;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1100;
    top: 0;
    right: 0;
    position: fixed;
    overflow-y: auto;
    scrollbar-width: none;

    @media (min-width: $bp-desktop) {
      width: 40%;
    } 
}

.side-nav-item{
  padding: 0;
}

@include media-breakpoint-down(lg) {
  html {
    font-size: 2rem;
  }
}

.title__description {
  font-weight: lighter;
}



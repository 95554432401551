:root {
    --font-primary: 'Inter';
    --bs-body-font-size: 1.5rem;

    @media (min-width: $bp-desktop) {
        --bs-body-font-size: 1.2rem;
    }
}

body {
    font-family: var(--font-primary), Arial, Helvetica, sans-serif;
}

b,
strong {
    color: $primary;
}

h1 strong {
    font-weight: lighter;
}

h1 {
    font-size: 7vw;
    font-weight: 700;
}


h3 {
    font-size: 2.3rem;
    font-weight: 700;
}

h4 {
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 2;
}

h5 {
    font-size: 1.5rem;
    font-size: 300;
}

.h-lg-18rem {
    @media (min-width: $bp-desktop) {
        height: 18rem !important;
    }
}

.h-15rem {
    height: 15rem;
}

section {
    padding: 0;
    padding-left: 0;
}

@include media-breakpoint-up(lg) {
    h1 {
        font-size: 3vw;
    }

    section {
        padding: 1rem;
        padding-left: 2rem;
    }
}

/* --- HOME --- */

#adjective {
    color: $primary;
}

.vertical-text {
    font-style: italic;
}

.icon-width {
    width: 20%;
}

.value {
    width: 100%
}

.h1-home {
    span {
        font-weight: 300;
    }
}

.project-preview-img {
    position: relative;
    width: 100%;
}

@include media-breakpoint-up(lg) {
    .icon-width {
        width: 60%;
    }

    .value {
        width: 23%;
    }

    .vertical-text {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: rotate(180deg);
        font-style: normal;
    }

    .project-preview-img {
        width: 60%;
    }
}

/* --- CATEGORIES --- */

/* --- PROJECTS --- */
.project_preview:nth-child(odd) {
    flex-direction: column !important;

    .project_img_line_preview {
        position: absolute;
        left: -5%;
        top: -5%;
        width: 20%;
        transform: rotate(90deg);
    }
}

.project_preview:nth-child(even) {
    flex-direction: column !important;

    .project_img_line_preview {
        position: absolute;
        right: -5%;
        top: -5%;
        width: 20%;
        transform: rotate(0deg);
    }
}


.img-project-preview-inside {
    width: 100%;
}

.project_header_img {
    width: 100%;
    position: absolute;
    top: 0;
}

.project_img_background {
    top: 12%;
    left: 0px;
    opacity: 35%;
    z-index: 0;
    width: 100%;
}

.project_header_icon {
    display: none;
}

.project_content {
    opacity: 100%;
    z-index: 100;
    display: flex;
    position: relative;
    background-color: $grey-light;
    color: $brand;
}

.project-page-title {
    font-size: 4rem;
}

.green {
    color: $secondary;
}

.red {
    color: $primary;
}

#project-content,
#service-content {
    width: 100%;
    padding: 0px !important;
}

#budget,
#sidebar {
    width: 100%;
    padding: 1rem;
}

.project-budget-bottom {
    display: none !important;
    padding-bottom: 1rem;
}

.hr-budget {
    opacity: 1;
    border-top: 3px solid;
}

.private-line-percentage {
    color: $blue;
}

.public-line-percentage {
    color: $violet;
}

.looking-line-percentage {
    color: $pink;
}

.btn-contact-budget {
    color: $grey-light;
}

.percentages-budget {
    font-size: 1.2rem;
    font-weight: lighter;

    @media (min-width: $bp-desktop) {
        font-size: 1rem;
    }
}

.project-header {
    z-index: 102;
    max-width: 100%;
}

.thumbnail-portrail {
    border-radius: 50%;
    width: 10%;
    padding: 0 1rem 0 1rem;
}

@include media-breakpoint-up(lg) {

    .project_preview:nth-child(odd) {
        flex-direction: row !important;
    }

    .project_preview:nth-child(even) {
        flex-direction: row-reverse !important;
    }

    .img-project-preview-inside {
        width: 95%;
    }

    .project_header_img {
        width: 50%;
        position: absolute;
        right: -1rem;
        top: 0;
    }

    #project-content {
        width: 80%;
    }

    #budget,
    #sidebar {
        width: 20%;
        padding: 1rem;
        z-index: 100;
        background-color: $brand;
        margin-top: 10%;
    }

    .project-budget-bottom {
        display: flex !important;
        padding-bottom: 5rem;
    }

    .project_header_icon {
        z-index: 101;
        width: 50%;
        top: 0%;
        right: 0px;
        display: block;
    }

    .project_img_background {
        top: 5%;
        right: 0px;
        opacity: 50%;
        z-index: 0;
        width: 50%;
        left: auto;
    }

    .project-header {
        max-width: 60%;
    }
}

/* --- ABOUT US --- */
.about-us-content {
    width: 100%;
}

@include media-breakpoint-up(lg) {
    .about-us-content {
        width: 70%
    }
}

.who-position-label {
    color: $secondary
}

.author-img-decorator {
    position: absolute;
    width: 50%;
    transform: rotate(90deg);
    left: -10%;
    top: -5%;
}

.not__found {
    width: 85%;
}

.share-buttons {
    padding-left: 2%;
}

.share-text {
    padding-top: 1%;
}


@charset "UTF-8";

@import "base/global",
"base/buttons",
"base/links",
"base/inputs",
"base/footer",
"base/header",
"base/cookies",
"plugin/quote",
"plugin/image",
"plugin/video",
"plugin/document",
"plugin/metrics",
"pages/modal.scss",
"pages/form.scss",
"pages/project.scss",
"pages/service.scss",
"pages/offer.scss",
"pages/projects.scss"

#sectionSelector { 
    background-color: $brand;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: flex;
    width: 100%;

    @media (min-width: $bp-desktop) {
        width: 50%;
    }
}

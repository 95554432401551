.info__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    flex-wrap: wrap;

    .info__text {
        color: $grey-light;
    }

    .info__btn {
        background-color: $grey-light;
        color: $primary;
        font-weight: bold;
    }
}

.project-content-box {

    a {
        color: $primary;
        &:hover {
            font-weight: bolder;
        }
    }

    p {
        text-wrap: balance;
        
        img {
            display: block;
            margin: auto;
            width: 100%;
            @media (min-width: $bp-desktop) {
                width: 60%;
            } 
        }
    }
}

#next_previous>div>div {
    flex-wrap: wrap;
    >div {
        width: 100%;
    }
    .project__divisor {
        display: none;
    }
    #next {
        justify-content: flex-end;
    }
    #previous {
        justify-content: flex-start;
    }
    @media (min-width: $bp-desktop) {
        flex-wrap: nowrap;
        >div {
            width: 50%;
        }
        .project__divisor {
            display: inline-block;
            width: 1px;
        }
        #next, #previous {
            justify-content: center;
        }
    }
}

#previous>a>span.link__icon {
    margin: auto;
    @media (min-width: $bp-desktop) {
        margin: 0;
    }
}

#next>a>span.link__icon {
    margin: auto;
    @media (min-width: $bp-desktop) {
        margin: 0;
    }
}

.project__divisor {
    height: 2em;
    align-self: center;
}

.img__portrail {
    width: 5em;
    height: 5em;
    overflow: hidden;
    border-radius: 50%;
    margin: 0.5em;

    img { 
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#project-content { 
    z-index: 999;
}

.email_footer{
    min-width: 50%;
}

.form-control {
    background-color: $input-background;
}

.form-control:focus {
    background-color: #5f4263;
    opacity: 45%;
}


.contact_form {
    width: 100%
}

.goteo-div {
    width: 30%;
    @media (min-width: $bp-desktop) {
        width: 100%;
    }
}

.goteo-logo {
    width: 100%;
    @media (min-width: $bp-desktop) {
        width: 30%;
    } 
}

.newsletter-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.sponsor-div img{
    width: 75%;
}

.sponsor-div>div>img:first-child {
    margin-bottom: 40px;
    @media (min-width: $bp-desktop) {
        margin-bottom: 0;
    }
}

@include media-breakpoint-up(lg) {
    .contact_form {
        width: 40%
    }
}
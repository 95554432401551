$body-color: #ffffff;
$brand: #462949;
$primary: #E94668;
$pink: #f4839b;
$secondary: #99FF99;
$grey-light: #F3F3EF;
$blue: #72D8FF;
$violet: #B792FF;
$violet-dark: #974ca1;
$green: #99FFCC;
$yellow: #FFFF99;
$input-background: #5f4263;
$black: #161616;

// Breakpoints
$bp-mobile-xs: 320px;
$bp-mobile: 375px;
$bp-phablet: 576px;
$bp-tablet: 768px;
$bp-desktop: 992px;
$bp-desktop-xl: 1200px;
$bp-desktop-xxl: 1520px;

$cta-background-color: $primary;
$button-cta-background-color: $cta-background-color;

@import "bootstrap/bootstrap";
@import "main";